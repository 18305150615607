<template>
	<div class="Wrap">
		<div class="Header">
			<img class="logo" src="/images/a-safe-logo.png" @click="clickedLogo" />
			<div class="Header_Le">
				<div class="Weather" v-if="site.selectSiteId != 'all'">
					<ul class="weather_list">
						<li class="weather_item temp">
							<div class="temp_text1">{{ weather.temperature }}˚</div>
						</li>
						<li class="weather_item temp">
							<div class="temp_text2">
								<span style="display: block">{{ weather.temperatureMax }}˚</span>
								<span style="display: block">{{ weather.temperatureMin }}˚</span>
							</div>
						</li>
						<li class="weather_item rain">
							<img :src="weather.weatherIcon" />
						</li>
						<li class="weather_item rain">
							<div class="rain_text">습도 {{ weather.humidity }}%</div>
						</li>
						<li class="weather_item info">
							<ul class="info_list">
								<li class="info_item">풍속 {{ weather.windSpeed }}m/s</li>
								<li class="info_item">풍향 {{ weather.windDeg }}</li>
							</ul>
						</li>
					</ul>
				</div>
				<select v-if="loginUserRolecd !== '04'" @change="changeSiteId()" v-model="site.selectSiteId">
					<option v-for="(item, index) in site.selectableSites" :key="index" :value="item.siteId">{{ item.siteNm }}</option>
				</select>
				<select
					v-if="pageParam.loginUserId.endsWith('admin') || loginUserRolecd === '00'"
					@change="changeCctvGrpId()"
					v-model="cctvGrpOptions.selectCctvGrpId"
				>
					<option value="">전체</option>
					<option v-for="(item, index) in cctvGrpOptions.selectableCctvGrps" :key="index" :value="item.cctvGrpId">{{ item.cctvGrpNm }}</option>
				</select>
				<i v-if="pageParam.loginUserId.endsWith('admin') || loginUserRolecd === '00'" class="fa fa-cog" @click="clickedCctvGroupPopup"></i>
			</div>
			<div class="Header_Ri">
				<p>{{ todayDate }}</p>
				<div class="Division">
					<button class="Division_1" @click="clickedDivisionChange(0)"></button>
					<button class="Division_4" @click="clickedDivisionChange(1)">
						<img src="/dashboardlib/images/4th.png" alt="4분할" />
					</button>
					<button class="Division_9" @click="clickedDivisionChange(2)">
						<img src="/dashboardlib/images/9th.png" alt="9분할" />
					</button>
					<button class="Division_16" @click="clickedDivisionChange(3)">
						<img src="/dashboardlib/images/16th.png" alt="16분할" />
					</button>
				</div>
			</div>
		</div>
		<div class="Container">
			<div class="Video_List">
				<div class="Place">
					<div class="swiper cctvListSwiper">
						<swiper class="swiper" :options="swiperOption">
							<swiper-slide v-for="(item, index) in cctvList" :key="index">
								<div class="Place_Btn">
									<input type="checkbox" v-model="item.isCctvOn" :id="index" @click="clickedCctvOnOff(item)" />
									<label :for="index">
										<span></span>
										<span>{{ item.cctvNm }}</span>
									</label>
								</div>
							</swiper-slide>
						</swiper>
					</div>
					<div style="cursor: pointer" class="swiper-button-prev arrow"></div>
					<div style="cursor: pointer" class="swiper-button-next arrow"></div>
				</div>
			</div>
			<div class="Video_Event">
				<div class="Video_Event_Ti">
					<p>이벤트 현황</p>
					<p>{{ eventDate }}</p>
				</div>
				<div class="Video_Event_Cnt">
					<span>{{ eventCount.danger }}</span>
					<span>{{ eventCount.caution }}</span>
					<span>{{ eventCount.action }}</span>
				</div>
				<div class="Video_Event_Ti">
					<p>▲ 이벤트 목록</p>
				</div>
				<div class="Video_Event_List">
					<div v-for="(item, index) in eventList.viewEvent" :key="index" class="Video_Events" :class="item.class">
						<p>
							[{{ item.cctvInstallPlaceCont }}] {{ item.objectNm }}
							{{ item.trespsDtctTimeNm ? ` - ${item.trespsDtctTimeNm}` : item.targetNm || '' }}
							{{ item.text }}
						</p>
						<div>
							<div class="Event_Le" style="cursor: pointer" @click="clickedEvnetList(item)">
								<img :src="item.imgVO.imgByte" />
							</div>
							<div class="Event_Ri">
								<div>
									<span>일시</span>
									<span>{{ item.occurDtm }}</span>
								</div>
								<div>
									<span>현장명</span>
									<span>{{ item.siteNm }}</span>
								</div>
								<div>
									<span>CCTV</span>
									<span>{{ item.cctvNm }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div :class="`zoom-popup ${zoomPopup.isActive ? 'active' : ''}`">
			<div class="zoom-popup-content">
				<div class="zoom-popup-top">
					<span>{{ zoomPopup.cctvNm }}</span>
					<img src="/dashboardlib/images/x_icon.png" @click="closeZoomPopup" />
				</div>
				<img :src="zoomPopup.activeImg" />
			</div>
		</div>
		<event-details-popup :show="showDetailsPopup" :detailsItem="curEventItem" :onClose="onCloseDetailsPopup" />
		<cctv-group-popup :show="showCctvGroupPopup" :detailsItem="curEventItem" :onClose="onCloseCctvGroupPopup" />
	</div>
</template>

<script>
// import InfiniteLoading from 'vue-infinite-loading';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import VueCookies from 'vue-cookies';
import _ from 'lodash';
import apiIndex from '../../api/index';
import EventDetailsPopup from '@/components/EventDetailsPopup';
import CctvGroupPopup from '@/components/CctvGroupPopup';

const dashboardUrl = apiIndex.dashboard;
let axiosExtention;
export default {
	components: {
		Swiper,
		SwiperSlide,
		EventDetailsPopup,
		CctvGroupPopup,
	},
	data: () => ({
		swiperOption: {
			slidesPerView: 6,
			loop: false,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		},
		pageParam: {
			loginUserSiteId: '',
			inqSite: '',
			loginUserRolecd: '',
			loginUserId: '',
		}, // /viewDashboard.do에서 return
		loginUserRolecd: '',
		todayDate: '',
		eventDate: '',
		tick: 1,
		divisionSelectIndex: 2, // 화면 분할 선택된 번호
		divisionCount: 4, // 화면 분할 선택지 수
		divisionSplitScreen: [1, 4, 9, 16], // 화면 분할 때 한 개 row의 cctv 수
		resolutionList: {
			1: {
				resolutionRow: 1280,
				resolutionCol: 720,
			},
			4: {
				resolutionRow: 752,
				resolutionCol: 423,
			},
			9: {
				resolutionRow: 500,
				resolutionCol: 276,
			},
			16: {
				resolutionRow: 368,
				resolutionCol: 208,
			},
		},
		divisionList: [], // 화면 분할 div
		// CCTV 목록
		cctvList: [],
		cctvView: [],
		// 이벤트 현황
		eventCount: {
			danger: 0, //위험
			caution: 0, // 주의
			action: 0, // 조치중
		},
		eventList: {
			viewEvent: [], // 화면 상에 뿌리고 있는 event 목록
			reloadEvent: [], // reload한 event 목록
		}, // 이벤트 목록
		site: {
			selectSiteId: null,
			selectableSites: null,
			selectSiteInfo: null,
			isSiteInfoLoadedCompletly: false,
		},
		// 유저의 cctv 그룹
		cctvGrp: null,
		// 관리자 전용 cctv 그룹 데이터
		cctvGrpOptions: {
			selectCctvGrpId: '',
			selectableCctvGrps: null,
			selectCctvGrpInfo: null,
		},
		weather: {
			weatherIcon: '',
			temperature: 0,
			temperatureMin: 0,
			temperatureMax: 0,
			humidity: 0,
			windSpeed: 0,
		},
		popup: {
			imgList: [],
			activeImg: { imgByte: '' },
			activeIndex: 0,
			page: 0,
			size: 10,
			timeOut: 800,
		},
		popupParam: null,
		abortController: null,
		curEventItem: {},
		zoomPopup: {
			activeImg: '/images/noimage.png',
			isActive: false,
		},
		showDetailsPopup: false,
		showCctvGroupPopup: false,
	}),
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.pageParam = JSON.parse(localStorage.getItem('dashboardPageParam'));
		this.cctvGrp = this.pageParam.inqCctvGrp; // CCTV 그룹에 의한 필터
		let that = this;
		this.abortController = new AbortController();
		this.setViewInfo().then(async () => {
			that.loginUserRolecd = that.pageParam.loginUserRolecd;
			that.site.selectSiteId = that.pageParam.loginUserSiteId;

			// 본사임원이면 전체 선택
			if ('04' === that.loginUserRolecd) {
				that.site.selectSiteId = 'all';
			}

			that.site.selectableSites = that.pageParam.inqSite;

			if (['00', '04'].includes(that.loginUserRolecd)) {
				that.site.selectableSites.unshift({ siteId: 'all', siteNm: '전체' });
			}
			that.site.selectSiteInfo = that.site.selectableSites.find(e => e.siteId == that.site.selectSiteId);
			that.todayDate = that.date();
			let index = VueCookies.get(that.pageParam.loginUserId + '_divisionOption');
			if (index == null) {
				index = 2;
			}
			that.divisionSelectIndex = index;

			await this.$axios.post(dashboardUrl.inqCctvGrp).then(({ data }) => {
				// 유저의 cctv 그룹 세팅
				if (!data.cctvGrpOptions && data.inqCctvGrp) {
					that.cctvGrp = data.inqCctvGrp;
				}
				// 관리자의 cctv 그룹 세팅
				if (data.cctvGrpOptions && data.cctvGrpOptions.length) {
					that.cctvGrpOptions.selectableCctvGrps = data.cctvGrpOptions;
					that.cctvGrpOptions.selectCctvGrpInfo = data.cctvGrpOptions[0];
				}
			});

			that.reload(that.site.selectSiteId);
			that.createDivisionHtml();
			that.initCctv(that.site.selectSiteId);
			setInterval(() => {
				that.todayDate = that.date();

				if (that.tick++ % 2 != 0) return;
				that.reload(that.site.selectSiteId);
				that.tick = 1;
			}, 1000);
		});
	},
	mounted() {
		// 엔진을 재시작하면 페이지를 새로고침해줘야 하는데, 이를 방지하기 위해
		// 5초마다 cctv 중 나오지 않고 있는 cctv를 찾아서 src를 refresh 해줌
		function isInViewport(element) {
			const rect = element.getBoundingClientRect();
			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight + 300 || document.documentElement.clientHeight + 300) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		}
		setInterval(() => {
			const curDivNum = this.divisionSplitScreen[this.divisionSelectIndex];
			const resolution = this.resolutionList[curDivNum];
			const images = document.querySelectorAll('.Video_Area .Videos > img[alt=CCTV]');
			images.forEach(img => {
				if (isInViewport(img)) {
					if (img.naturalWidth == 0 && img.naturalHeight == 0) {
						const cctvId = img.id.replace('c', '');
						const cctvIdx = this.cctvList.findIndex(cctv => cctv.cctvId == cctvId);
						img.src = `${this.cctvList[cctvIdx].cctvPlayUrl}/${this.cctvList[cctvIdx].cctvId}/${resolution.resolutionRow}/${
							resolution.resolutionCol
						}?${new Date().getTime()}`;
					}
				}
			});
		}, 5000);
	},
	computed: {},
	watch: {},
	methods: {
		date() {
			const date = new Date();
			const yyyy = date.getFullYear().toString();
			const mm = (date.getMonth() + 1).toString();
			const dd = date.getDate().toString();
			const h = date.getHours().toString();
			const m = date.getMinutes().toString();
			const s = date.getSeconds().toString();
			const week = ['일', '월', '화', '수', '목', '금', '토'];

			return (
				yyyy +
				'.' +
				this.addZero(mm) +
				'.' +
				this.addZero(dd) +
				'. ' +
				week[date.getDay()] +
				' ' +
				this.addZero(h) +
				':' +
				this.addZero(m) +
				':' +
				this.addZero(s)
			);
		},
		addZero(string) {
			// 값이 1자리면 앞에 '0' 붙임
			return string[1] ? string : '0' + string[0];
		},
		// cctv 정보 설정
		initCctv(siteId) {
			const param = {
				siteId: siteId, // 검색 조건
			};

			// CCTV 정보를 가져온다.
			this.$axios({
				url: dashboardUrl.inqCctv,
				method: 'POST',
				data: param,
				signal: this.abortController.signal,
			})
				// await this.$axios
				// 	.post(dashboardUrl.inqCctv, param)
				.then(
					function (response) {
						if (this.cctvGrp) {
							response.data = response.data.filter(cctv => this.cctvGrp.cctvIdList.includes(cctv.cctvId));
						}

						const curDivNum = this.divisionSplitScreen[this.divisionSelectIndex];
						const resolution = this.resolutionList[curDivNum];
						this.cctvList = response.data;

						for (let index = 0; index < this.cctvList.length; index++) {
							this.$set(this.cctvList[index], 'id', 'c' + this.cctvList[index].cctvId);

							this.$set(
								this.cctvList[index],
								'src',
								`${this.cctvList[index].cctvPlayUrl}/${this.cctvList[index].cctvId}/${resolution.resolutionRow}/${resolution.resolutionCol}`,
							);
							//TEMP
							// this.$set(
							// 	this.cctvList[index],
							// 	'src',
							// 	process.env.VUE_APP_CCTV_PLAY_URL +
							// 		this.cctvList[index].cctvPlayUrl.substr(this.cctvList[index].cctvPlayUrl.lastIndexOf(':')) +
							// 		'/' +
							// 		this.cctvList[index].cctvId,
							// );
							this.$set(this.cctvList[index], 'isCctvOn', true);
							this.$set(this.cctvList[index], 'videoArea', '');
						}

						// 쿠키에 저장된 값으로 CCTV on & off 제어
						let savedCctvOnOffCookies = JSON.parse(VueCookies.get(this.pageParam.loginUserId + '_cctvOnOff'));
						if (savedCctvOnOffCookies != null) {
							for (let index = 0; index < savedCctvOnOffCookies.length; index++) {
								const findElement = this.cctvList.find(e => e.id == savedCctvOnOffCookies[index].id);
								if (findElement != null) {
									findElement.isCctvOn = savedCctvOnOffCookies[index].isCctvOn;
								}
							}
						} else {
							savedCctvOnOffCookies = [];
						}

						for (let index = 0; index < this.cctvList.length; index++) {
							const findElement = savedCctvOnOffCookies.find(e => e.id == this.cctvList[index].id);
							// savedCctvOnOffCookies 값에 추가된 CCTV 추가
							if (findElement == null) {
								savedCctvOnOffCookies.push({ id: this.cctvList[index].id, isCctvOn: this.cctvList[index].isCctvOn });
							}
						}

						VueCookies.set(this.pageParam.loginUserId + '_cctvOnOff', JSON.stringify(savedCctvOnOffCookies), { expires: 365 });

						this.createCctvHtml();

						this.cctvView = _.cloneDeep(this.cctvList.filter(e => e.isCctvOn));
						this.createSwiperSlideHtml();

						$('.Divisions_All').hide();
						$('.Divisions_' + this.divisionSplitScreen[this.divisionSelectIndex]).show('slow');
						this.isSiteInfoLoadedCompletly = true;
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		// 현장 변경되면 데이터 reset
		changeSiteId() {
			this.abortController.abort();
			this.abortController = new AbortController();
			// 현장이 바뀌어 모든 데이터가 준비되지 않았는데, 현장을 바꿔 생기는 오류 방지
			// if (this.site.isNotChangeCompletedSite) {
			// this.site.isChangeCompletedSite = false;
			this.site.selectSiteInfo = this.site.selectableSites.find(e => e.siteId == this.site.selectSiteId);

			for (let index = 0; index < this.cctvList.length; index++) {
				let img = document.getElementById(this.cctvList[index].id);
				if (img != null) {
					img.src = null;
				}
			}

			// swiper-wrapper div의 하위 cctv 전부 삭제
			const swiperWrapper = document.getElementsByClassName('swiper-wrapper' + this.divisionSelectIndex);
			swiperWrapper[0].replaceChildren();

			this.eventCount = {
				danger: 0, //위험
				caution: 0, // 주의
				action: 0, // 조치중
			};
			this.eventList = {
				viewEvent: [], // 화면 상에 뿌리고 있는 event 목록
				reloadEvent: [], // reload한 event 목록
			};

			this.reload(this.site.selectSiteId);
			this.initCctv(this.site.selectSiteId);
			this.tick = 1;

			// this.site.isChangeCompletedSite = true;
			// } else {
			// 	alert('잠시 후에 다시 시도해주세요.');
			// }
		},
		changeCctvGrpId() {
			if (this.cctvGrpOptions.selectCctvGrpId) {
				// 전체가 아닌 경우
				this.cctvGrp = { ...this.cctvGrpOptions.selectableCctvGrps.find(cctvGrp => cctvGrp.cctvGrpId === this.cctvGrpOptions.selectCctvGrpId) };
			} else {
				// 전체인 경우 초기화
				this.cctvGrp = null;
			}

			this.changeSiteId();
		},
		reload(siteId) {
			// 날씨
			if (navigator.geolocation) {
				this.getWeather(this.site.selectSiteInfo.latitude, this.site.selectSiteInfo.longitude);
			} else {
				console.log("Can't access geo location");
			}

			const param = {
				siteId: siteId, // 검색 조건
				cctvIdList: this.cctvGrp ? this.cctvGrp.cctvIdList : null,
			};

			// 위험, 주의, 조치중 건 수, 위험, 주의 이벤트
			//여기서 못 가져옴
			this.$axios({
				url: dashboardUrl.reload,
				method: 'POST',
				data: param,
				signal: this.abortController.signal,
			})
				// await this.$axios
				// 	.post(dashboardUrl.reload, param)
				.then(
					function (response) {
						this.eventDate = this.date() + ' 기준';

						this.eventCount.danger = response.data.dangerOccurCnt; //위험
						this.eventCount.caution = response.data.warnWorkOccurCnt; // 주의
						this.eventCount.action = response.data.dangerActionCnt; // 조치중

						// 위험, 주의 이벤트
						this.eventList.reloadEvent = response.data.inqDashboardEventListOutVOs;
						if (this.eventList.reloadEvent.length > 0) {
							this.eventList.reloadEvent.forEach(e => {
								// load 시 값이 없어서 console에 오류남
								// default 값 넣어서 해결
								e.imgVO = {
									fileName: null,
									imgByte: '/images/noimage.png',
									oriFileName: null,
								};
							});
						}

						// cctv src update
						// this.cctvView.forEach(cctvViewItem => {
						// 	let img = document.getElementById(cctvViewItem.id);
						// 	if (img != null) {
						// 		img.src = null;
						// 		cctvViewItem.videoArea
						// 			.querySelector('#' + cctvViewItem.id)
						// 			.setAttribute('src', cctvViewItem.src.split('?t=')[0] + '?t=' + new Date().getTime());
						// 	}
						// });

						this.updateEventList();
					}.bind(this),
				)
				// 	.catch(axiosExtention.buildErrorHandler());
				.catch(error => {
					if (error.message != 'canceled') {
						console.log('Error', error.message);
					}
				});
		},
		async setViewInfo() {
			let that = this;
			if (this.pageParam == null || this.pageParam == undefined) {
				await this.$axios
					.post(dashboardUrl.viewDashboard, {})
					.then(response => {
						that.pageParam = response.data;
						localStorage.setItem('dashboardPageParam', JSON.stringify(that.pageParam));
					})
					.catch(() => {
						alert('오류가 발생했습니다.');
						window.close();
					});
			}
		},
		// 이벤트 목록 업데이트
		updateEventList() {
			let viewEventKey = [];
			this.eventList.viewEvent.forEach(e => {
				viewEventKey.push(e.key);
			});
			let reloadEventKey = [];
			this.eventList.reloadEvent.forEach(e => {
				reloadEventKey.push(e.key);
			});
			// reloadEvent에 없고, ViewEvent에 있으면 삭제
			let viewSubtractionReload = viewEventKey.filter(key => !reloadEventKey.includes(key));
			this.eventList.viewEvent.forEach((viewEventItem, index, viewEvent) => {
				viewSubtractionReload.forEach(key => {
					if (viewEventItem.key == key) {
						viewEventItem.class = viewEventItem.class + ' ' + 'Out';
						setTimeout(() => {
							viewEvent.splice(viewEvent.indexOf(viewEventItem), 1);
						}, 1000);
					}
				});
			});

			// reloadEvent에 있고, viewEvent에 없으면 추가
			let reloadSubtractionView = reloadEventKey.filter(key => !viewEventKey.includes(key));
			this.eventList.reloadEvent.forEach(reloadEventItem => {
				reloadSubtractionView.forEach(key => {
					if (reloadEventItem.key == key) {
						if (reloadEventItem.warnWork) {
							reloadEventItem.text = '주의 발생';
							reloadEventItem.class = 'Video_Events_Warn_Work' + ' ' + 'Hide In';
						} else {
							reloadEventItem.text = '위험 발생';
							reloadEventItem.class = 'Video_Events_Danger' + ' ' + 'Hide In';
						}
						this.$axios
							.post(dashboardUrl.readThumImage, { imgSaveFileNm: reloadEventItem.imgSaveFileNm })
							.then(
								function (response) {
									if (response.data != null) {
										reloadEventItem.imgVO = response.data;
										reloadEventItem.imgVO.imgByte = 'data:image/jpg;base64,' + response.data.imgByte;
									}
								}.bind(this),
							)
							.catch(axiosExtention.buildErrorHandler());
						this.eventList.viewEvent.unshift(reloadEventItem);
						setTimeout(() => {
							reloadEventItem.class = reloadEventItem.class.replace(' ' + 'Hide In', '');
						}, 1000);
					}
				});
			});
		},
		// 화면 분할 DIV 생성
		createDivisionHtml() {
			for (let i = 0; i < this.divisionCount; i++) {
				// div class='Divisions_1 Divisions_All'
				const divisions = document.createElement('div');
				divisions.classList.add('Divisions_' + this.divisionSplitScreen[i]);
				divisions.classList.add('Divisions_All');

				{
					// div class='swiper'
					const swiper = document.createElement('div');
					swiper.classList.add('swiper');
					swiper.classList.add('swiper' + i);
					divisions.appendChild(swiper);

					{
						// div class='swiper-wrapper'
						const swiperWrapper = document.createElement('div');
						swiperWrapper.classList.add('swiper-wrapper');
						swiperWrapper.classList.add('swiper-wrapper' + i);
						swiper.appendChild(swiperWrapper);
					}
				}

				this.divisionList[i] = divisions;
				const videoList = document.getElementsByClassName('Video_List');
				videoList[0].appendChild(divisions);
			}
		},
		// CCTV 목록에 따라 HTML 생성
		createCctvHtml() {
			// div에 따라 지정된 row에 들어갈 수 있는 cctv 개수만큼 row를 만들어야한다
			const cctvLength = this.cctvList.length;
			for (let index = 0; index < cctvLength; index++) {
				// div class='Video_Area'
				const videoArea = document.createElement('div');
				videoArea.classList.add('Video_Area');
				// cctv 클릭 시 팝업 띄움
				videoArea.setAttribute('style', 'cursor: pointer');
				videoArea.addEventListener('click', () => {
					this.zoomPopup.activeImg = this.cctvList[index].src.replace(/\/\d+\/\d+$/, '/1280/720');
					this.zoomPopup.cctvNm = this.cctvList[index].cctvNm;
					this.zoomPopup.isActive = true;
				});
				{
					// div class='Videos'
					const videos = document.createElement('div');
					videos.classList.add('Videos');
					videoArea.appendChild(videos);

					{
						// button
						const closeButton = document.createElement('button');
						closeButton.classList.add('Close');
						closeButton.onclick = e => {
							e.stopPropagation();
							return this.clickedCctvOnOff(this.cctvList[index]);
						};
						videos.appendChild(closeButton);

						{
							//img
							const closeImg = document.createElement('img');
							closeImg.setAttribute('src', '/dashboardlib/images/x_icon.png');
							closeButton.appendChild(closeImg);
						}
					}

					{
						// img
						const cctvImg = document.createElement('img');
						cctvImg.setAttribute('id', this.cctvList[index].id);
						cctvImg.setAttribute('src', this.cctvList[index].src);
						cctvImg.setAttribute('alt', 'CCTV');
						videos.appendChild(cctvImg);
					}
				}

				{
					// div class='Video_Info'
					const videoInfo = document.createElement('div');
					videoInfo.classList.add('Video_Info');
					videoArea.appendChild(videoInfo);
					{
						// p
						const name = document.createElement('p');
						name.innerText = this.cctvList[index].cctvNm;
						videoInfo.appendChild(name);

						// a class='N_Window'
						const popupLink = document.createElement('a');
						popupLink.classList.add('N_Window');
						videoInfo.appendChild(popupLink);

						{
							// img
							// const popupImg = document.createElement('img');
							// popupImg.setAttribute('src', '/dashboardlib/images/Detail.png');
							// popupImg.setAttribute('alt', '새창으로 열기');
							// popupLink.appendChild(popupImg);
						}
					}
				}

				this.cctvList[index].videoArea = videoArea;
			}
		},
		// 화면 분할에 맞게 swiperSlide를 생성하고 swiperSlide에 cctv area를 붙임
		createSwiperSlideHtml() {
			const swiperWrapper = document.getElementsByClassName('swiper-wrapper' + this.divisionSelectIndex);

			const cctvLength = this.cctvView.length;

			const numberOfCctvInOneLine = [1, 2, 3, 4];
			let appendCount = 0;

			let swiperSlide = document.createElement('div');
			swiperSlide.classList.add('swiper-slide');

			swiperWrapper[0].appendChild(swiperSlide);

			for (let index = 0; index < cctvLength; index++) {
				if (!this.cctvGrp || (this.cctvGrp && this.cctvGrp.cctvIdList.includes(this.cctvList[index].cctvId))) {
					if (this.cctvView[index].isCctvOn) {
						if (numberOfCctvInOneLine[this.divisionSelectIndex] == appendCount) {
							swiperSlide = document.createElement('div');
							swiperSlide.classList.add('swiper-slide');

							swiperWrapper[0].appendChild(swiperSlide);
							appendCount = 0;
						}
						swiperSlide.appendChild(this.cctvView[index].videoArea);
						appendCount++;
					}
				}
			}
		},
		clickedLogo() {
			window.open('/');
		},
		// 화면 분할 변경 시
		clickedDivisionChange(index) {
			VueCookies.set(this.pageParam.loginUserId + '_divisionOption', index, { expires: 365 });

			// swiper-wrapper div의 하위 cctv 전부 삭제
			const swiperWrapper = document.getElementsByClassName('swiper-wrapper' + this.divisionSelectIndex);
			swiperWrapper[0].replaceChildren();

			this.divisionSelectIndex = index;
			$('.Divisions_All').hide();
			$('.Divisions_' + this.divisionSplitScreen[this.divisionSelectIndex]).show('slow');

			// 재생성
			this.createSwiperSlideHtml();

			// cctv url 해상도별로 다시 수정
			window.stop();
			const curDivNum = this.divisionSplitScreen[this.divisionSelectIndex];
			const resolution = this.resolutionList[curDivNum];
			document.querySelectorAll('.Video_Area img[id]').forEach(elem => {
				elem.src = '';
				const cctvId = elem.id.replace('c', '');
				const cctvIdx = this.cctvList.findIndex(cctv => cctv.cctvId == cctvId);
				elem.src = `${this.cctvList[cctvIdx].cctvPlayUrl}/${cctvId}/${resolution.resolutionRow}/${resolution.resolutionCol}`;
			});
		},
		// CCTV on off 시
		clickedCctvOnOff(cctvItem) {
			if (cctvItem.isCctvOn) {
				let img = document.getElementById(cctvItem.id);
				img.src = null;

				for (let index = 0; index < this.cctvView.length; index++) {
					if (this.cctvView[index].id == cctvItem.id) {
						this.cctvView.splice(index, 1);
					}
				}
				cctvItem.isCctvOn = !cctvItem.isCctvOn;
			} else {
				cctvItem.videoArea.querySelector('#' + cctvItem.id).setAttribute('src', cctvItem.src);

				cctvItem.isCctvOn = !cctvItem.isCctvOn;
				this.cctvView.push(_.cloneDeep(cctvItem));
			}

			const savedCctvOnOffCookies = JSON.parse(VueCookies.get(this.pageParam.loginUserId + '_cctvOnOff'));

			if (savedCctvOnOffCookies != null) {
				savedCctvOnOffCookies.find(e => e.id == cctvItem.id).isCctvOn = cctvItem.isCctvOn;
			}

			VueCookies.set(this.pageParam.loginUserId + '_cctvOnOff', JSON.stringify(savedCctvOnOffCookies), { expires: 365 });

			// swiper-wrapper div의 하위 cctv 전부 삭제
			const swiperWrapper = document.getElementsByClassName('swiper-wrapper' + this.divisionSelectIndex);
			swiperWrapper[0].replaceChildren();

			// 재생성
			this.createSwiperSlideHtml();
		},
		// 이벤트 목록의 사진 클릭 시
		clickedEvnetList(eventItem) {
			if (eventItem.warnWork) {
				// 주의
				eventItem.videoWarnActnDvsnCd = eventItem.videoActnDvsnCd;
				eventItem.warnWorkOccurDtm = eventItem.occurDtm;
			} else {
				// 위험
				eventItem.videoDangerActnDvsnCd = eventItem.videoActnDvsnCd;
				eventItem.dangerOccurDtm = eventItem.occurDtm;
			}

			this.curEventItem = eventItem;
			this.showDetailsPopup = true;
		},
		// 팝업의 아래 이미지 클릭 시
		clickedPopupImage(e, index) {
			this.$axios
				.post(dashboardUrl.readImage, { imgSaveFileNm: this.popup.imgList[index].fileName })
				.then(
					function (response) {
						if (response.data != null) {
							this.popup.activeImg = response.data;
							this.popup.activeIndex = index;

							if (e) {
								const fullWidth = document.querySelector('body').offsetWidth; // 현재 보이는 영역 길이
								const modalBottom = this.$refs.modalBottom; // modal bottom element
								const clientXInModalBottom = e.clientX - (fullWidth - modalBottom.offsetWidth) / 2; // modalBottom 기준 클릭된 X 좌표 계산

								if ((clientXInModalBottom / modalBottom.offsetWidth) * 100 > 80) {
									// 80% 정도 영역에서 클릭 시 스크롤
									this.scrollToRight();
								}
							}
						}
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		scrollToRight() {
			document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
		},
		getWeather(lat, log) {
			if (this.site.selectSiteId == 'all') {
				return;
			}
			const API_KEY = 'f42c8c69cb4e3987a7a8c8cc2eb15cc4';
			fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${log}&appid=${API_KEY}&units=metric`)
				.then(response => {
					return response.json();
				})
				.then(resultJson => {
					const weatherData = resultJson;
					this.weather.weatherIcon = this.getWeatherIcon(weatherData.weather[0].icon);
					this.weather.temperature = Math.round(weatherData.main.temp);

					this.weather.temperatureMin = Math.round(weatherData.main.temp_min);
					this.weather.temperatureMax = Math.round(weatherData.main.temp_max);

					this.weather.humidity = weatherData.main.humidity;
					this.weather.windSpeed = weatherData.wind.speed;
					this.weather.windDeg = this.getWindDirection(weatherData.wind.deg);
				});
		},
		getWeatherIcon(iconState) {
			switch (iconState) {
				case '01d':
					return '/dashboardlib/images/weather/sun.png';
				case '02d':
					return '/dashboardlib/images/weather/cloud-sun.png';
				case '03d':
					return '/dashboardlib/images/weather/cloud.png';
				case '04d':
					return '/dashboardlib/images/weather/cloud.png';
				case '09d':
					return '/dashboardlib/images/weather/cloud.png';
				case '10d':
					return '/dashboardlib/images/weather/umbrella.png';
				case '11d':
					return '/dashboardlib/images/weather/storm.png';
				case '13d':
					return '/dashboardlib/images/weather/snowflake.png';
				case '50d':
					return '/dashboardlib/images/weather/cloud.png';
				default:
					return '/dashboardlib/images/weather/sun.png';
			}
		},
		getWindDirection(degree) {
			if (degree > 337.5) return '북';
			if (degree > 292.5) return '북서';
			if (degree > 247.5) return '서';
			if (degree > 202.5) return '남서';
			if (degree > 157.5) return '남';
			if (degree > 122.5) return '남동';
			if (degree > 67.5) return '동';
			if (degree > 22.5) return '북동';
			return '북';
		},
		closeZoomPopup() {
			this.zoomPopup.isActive = false;
		},
		onCloseDetailsPopup() {
			this.showDetailsPopup = false;
		},
		clickedCctvGroupPopup() {
			this.showCctvGroupPopup = true;
		},
		onCloseCctvGroupPopup() {
			this.showCctvGroupPopup = false;
		},
	},
};
</script>
<style scoped>
.Header .logo {
	width: auto;
	height: 43px;
	float: left;
	cursor: pointer;
	margin-right: 30px;
}

.Header_Le .fa-cog {
	font-size: 1.8em;
	cursor: pointer;
	margin-left: 20px;
}

.zoom-popup {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	display: none;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background: rgb(0, 0, 0, 0.5);
	color: black;
	z-index: 100;
	top: 0;
	left: 0;
	padding: 12px;
}

.zoom-popup.active {
	display: flex;
}

.zoom-popup .zoom-popup-content {
	position: relative;
	min-width: 50%;
	height: 100%;
	background: white;
	border-radius: 12px;
	padding: 12px;
	overflow: hidden;
}

.zoom-popup .zoom-popup-content .zoom-popup-top {
	position: absolute;
	top: 12px;
	left: 12px;
	width: calc(100% - 24px);
	display: flex;
	align-items: center;
	color: white;
	background: rgba(0, 0, 0, 0.3);
	font-size: 1.6em;
	font-weight: bold;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 10px 20px 10px 20px;
}

.zoom-popup .zoom-popup-content .zoom-popup-top span {
	margin-left: auto;
	margin-right: 20px;
}

.zoom-popup .zoom-popup-content .zoom-popup-top img {
	width: 40px;
	height: 40px;
	cursor: pointer;
	z-index: 200;
	overflow: hidden;
}

.zoom-popup .zoom-popup-content .zoom-popup-body {
	height: 100%;
	overflow: hidden;
}

.zoom-popup .zoom-popup-content img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 10px;
}
</style>
